.tasks-page .task-list  {
    background: unset;
    padding: 0;
}

.tasks-page .button-red {
    margin-top: 1rem;
    width: 100%;
}

.task-list .task-item {
    width: 100%;
    justify-content: center;
    padding: 16px 10px;
    margin-bottom: 0;
    border-radius: 0;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.task-list .task-item span {
    font-size: 20px;
    margin-right: 10px;
}


.task-list  .task-item {
    margin-bottom: 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-list .task-item .task-item__element {
    display: flex;
    align-items: center;
    text-align: left;
}

.task-list .task-item .task-icon {
    font-size: 30px;
    line-height: 30px;
    width: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 16px;
}

.task-list .task-item span {
    margin-bottom: 0;
    display: inline;
}

.task-list .task-item:not(:last-of-type) {
    margin-bottom: 5px;;
}

.tasks-page .swiper {
    width: 100%;
}

.tasks-page .swiper-slide {
    width: 85%;
}

.tasks-page-scroll-container {
    max-height: 100vh;
    overflow-y: scroll;

}

.tasks-page-scroll-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
  
.tasks-page-scroll-container::-webkit-scrollbar {
    display: none; 
}