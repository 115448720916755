.ton-connect-page__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.ton-connect-page__button {
  margin: 16px auto 0;
}

.ton-connect-page__button-connected {
  margin: 16px 24px 16px auto;
}

.wallet-page input {
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
}

.wallet-page button {
  margin-top: 10px;
}
