.flip-coin-container {
    position: absolute;
    width: 60px;
    height: 60px;
    perspective: 1000px;
    cursor: pointer;
    animation: appear 1s ease-out;
}

@keyframes appear {
    from {
        opacity: 0;
        transform: scale(0.1);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.flip-coin-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flipped .flip-coin-inner {
    transform: rotateY(180deg);
}

.flip-coin-front, .flip-coin-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flip-coin-front img {
    width: 100%;
    height: 100%;
}

.flip-coin-back {
    background-color: gold;
    color: black;
    transform: rotateY(180deg);
    font-weight: bold;
    font-size: 16px;
    border-radius: 100%;
}

.disappearing {
    animation: disappear 0.5s forwards;
}

@keyframes disappear {
    to {
        opacity: 0;
        transform: scale(0);
    }
}

.coin-container {
    position: relative;
    /* Add any other necessary styles */
}

.flip-coin-container {
    position: absolute;
    /* Remove top and left positioning if you want it to be relative to the coin-container */
}

.missed-popup {
    color: white;
    font-size: 20px;
    animation: fadeUp 2s linear;

}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    20% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-150px);
    }
}