/* Add this to your existing CSS */

.home.inactive,
.menu-container.inactive {
    pointer-events: none;
    opacity: 0.5;
    filter: blur(2px);
}

.turbo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(48, 16, 107, 0.8);
    pointer-events: auto;
}


.turbo-timer {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 24px;
    z-index: 1001;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.top-elements {
    position: relative;
    z-index: 130;
}

.top-elements .username {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-elements .username .wallet-link {
    font-size: 30px;
}