.rgb {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.rgb__icon {
  width: 18px;
  aspect-ratio: 1;
  border: 1px solid #555;
  border-radius: 50%;
}