.display-data__header {
  font-weight: 400;
}

.display-data__line {
  padding: 16px 24px;
}

.display-data__line-title {
  color: var(--tg-theme-subtitle-text-color);
}

.display-data__line-value {
  word-break: break-word;
}
