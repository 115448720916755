.current-yard-page {
    padding: 20px 0;
}

.yard-name {
    margin-bottom: 40px;
}

.current-yard-list {
    max-height: calc(100vh - 300px);
    position: relative;
    overflow-y: hidden;
   
}
.current-yard-container::-webkit-scrollbar {
    display: none; 
  }

.current-yard-container {
    max-height: calc(100vh - 300px);
    padding-bottom: 40px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: scroll;
    height: 100%;
}

.current-yard-container.with-current {
    max-height: calc(100vh - 400px);
}

.current-yard-list.with-current {
    max-height: calc(100vh - 400px);
}


.current-yard-list::after {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 95%);

    width: 100%;
    height: 40px;
    z-index: 2;
    left: 0;
    bottom: -1px;
    user-select: none;

}

.yard-name .yard-name__img {
    width: 52px;
    height: 52px;
    overflow: hidden;
    border-radius: 8px;
    margin: 0 auto 16px auto;
}

.yard-name__name {
    font-size: 24px;
    font-weight: 650;
    line-height: 30.34px;
    text-align: center;
    color: white;
    margin-bottom: 4px;
}

.yard-name__item-score {
    font-size: 14px;
    line-height: 17.7px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.yard-name__item-score img {
    width: 15px;
    height: 15px;
    transform: translateY(3px);
    margin-right: 4px;
}

.yard-name__item-score-members {
    font-size: 14px;
    line-height: 17.7px;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 4px;
}

.current-yard-page .button-red {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.current-yard-page .button-red svg {
    margin-right: 5px;
}

.current-yard__item {
    padding: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 24px;
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
}

.current-yard__item-content {
    display: flex;
    align-items: center;
}

.current-yard__item:not(:last-of-type) {
    margin-bottom: 12px;
}

.current-yard__item-content .current-yard__item-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 16px;
    overflow: hidden;
}

.current-yard__item-content .current-yard__item-stats {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17.7px;
    text-align: left;
}

.current-yard__item-content .current-yard__item-stats .current-yard__item-medal {
    font-size: 14px;
    line-height: 17.7px;
    text-align: left;
    margin-right: 4px;
}

.current-yard__item-score {
    font-size: 14px;
    line-height: 17.7px;
    color: white;
    display: flex;
    align-items: center;
}

.current-yard__item-score img {
    width: 15px;
    height: 15px;
    transform: translateY(0px);
    margin-right: 4px;
}

.current-yard__item-score-members {
    font-size: 14px;
    line-height: 17.7px;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 4px;
}

.current-yard__item-medal {
    font-size: 32px;
}