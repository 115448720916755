@font-face {
    font-family: Roobert;
    src: url('../public/fonts/RoobertPRO-Regular.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: Roobert;
    src: url('../public/fonts/RoobertPRO-Bold.ttf');
    font-display: swap;
    font-weight: bold;
  }
* {
    -webkit-touch-callout: none; /* Prevents callouts like long-press context menus on iOS */
    -webkit-user-select: none; /* Disables text selection in Safari */
    -khtml-user-select: none; /* Disables text selection in older Konqueror */
    -moz-user-select: none; /* Disables text selection in Firefox */
    -ms-user-select: none; /* Disables text selection in Internet Explorer */
    user-select: none; /* Disables text selection in non-prefixed browsers */
}

html, body {
    touch-action: none; /* Prevents all touch actions like scrolling, zooming */
    overscroll-behavior: contain; /* Prevents scroll chaining or pull-to-refresh actions */
    -webkit-tap-highlight-color: transparent; /* Removes the highlight color on tap */
}

*,
*::before,
*::after {
    box-sizing: border-box; /* Consistent box-sizing across all elements */
    margin: 0;
    padding: 0;
}

img {
    -webkit-user-drag: none; /* Prevents dragging of images in Safari */
}

a {
    text-decoration: none;
    color: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: Roobert, sans-serif;
    height: 100vh;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.95);
    overflow-y: hidden;
    -webkit-user-zoom: none; /* Safari */
    -moz-user-zoom: none; /* Firefox */
    -ms-user-zoom: none; /* IE */
    zoom: none; /* Standard zoom */
    overflow: hidden;
}

body.flipcoin-active {
    animation: shakeBackground 0.5s ease-in-out infinite; /* Apply shake animation */
}

@keyframes shakeBackground {
    0% { background-position: center; }
    25% { background-position: left 5px; }
    50% { background-position: center; }
    75% { background-position: right 5px; }
    100% { background-position: center; }
}

#root {
    height: 100%;
}

.App {
    min-height: 100vh;
    height: 100%;
}

#wrap {
    min-height: 100vh;
    height: 100%;
}

#content {
    min-height: 100vh;

    height: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    max-width: 480px;
    text-align: center;
    padding: 16px;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url('https://fastly.picsum.photos/id/111/4400/2656.jpg?hmac=leq8lj40D6cqFq5M_NLXkMYtV-30TtOOnzklhjPaAAQ') no-repeat center center;
    background-size: cover;
    margin: 0 auto;
}

.score {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2.5rem;
    margin: 10px 0;
}

.score-wrapper {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.score-wrapper img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 8px;
}

.league {
    font-size: 1.2em;
    color: #fff;
}

.circle {
    width: 150px;
    height: 150px;
    background-color: #333;
    border-radius: 50%;
    margin: 20px auto;
}

.energy-title {
    color: #fff;
    font-size: 24px;
}

.progress {
    width: 80%;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background: linear-gradient(90deg, #FF4D4D, #5E9BF9);
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
}

.energy-container {
    text-align: center;
}

.energy-element {
    display: flex;
    margin-right: 20px;
    align-items: center;
}

.energy-title {
    font-size: 24px;
    line-height: 20px;
}

.energy-title span {
    font-size: 16px;
    line-height: 20px;

    color: rgba(255, 255, 255, 0.5);
}

.energy-bar {
    position: relative;
    width: 42; 
    height: 75px; 
}
.energy-image {
    position: relative;
    width: 70px;
    height: 70px;
    z-index: 0;
}

.energy-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 2;
    opacity: 0.5; 
}
.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center text vertically and horizontally */
    color: #ffffff; /* White text */
    font-weight: bold; /* Bold text for better readability */
    font-size: 16px; /* Adjust font size as needed */
    pointer-events: none; /* Ensure the text is not clickable */
}

.booster-container, .upgrades-container {
    display: flex;
    justify-content: space-between;
}

.booster-container {
    padding: 5px 0;
}

.booster, .upgrade {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: #000;
    border-radius: 10px;
    color: #fff;
    text-align: center;
}

.booster span, .upgrade span {
    display: block;
    margin-bottom: 10px;
}

.booster-container .booster {
    width: 35%;
}

.boosts-list::-webkit-scrollbar {
    display: none;
}

.intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #fff;
    background-color: #000000;
    text-align: center;
}

.logo {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
}

.intro-text-header {
    margin-top: 16px;
    font-size: 40px;
    font-weight: 750;
}

.intro-text-description {
    margin-top: 8px;
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 400;
}

.intro-text-link {
    color: #fff;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
}

.join-button {
    width: 300px;
    height: 55px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 30px;
    font-size: 1.2em;
    font-weight: 650;
    color: #fff;
    background-color: #000;
    border-radius: 100px;
    border: 1px solid #fff;
    cursor: pointer;
}

.join-button:hover {
    background-color: #444;
}

.intro-text span {
    font-size: 1.5em;
    margin-right: 5px;
}


/*Steps*/

.user-start-stats-container {
    position: relative;
    height: 100vh;
    text-align: center;
    padding: 20px;
}

.user-start-stats-container .button-red {
    width: 100%;
    position: absolute;
    bottom: 74px;
    left: 0;
}

.rewards-container {
    width: 100%;
    margin-top: 53px;
    margin-bottom: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    color: #fff;
}

.reward-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: #000;
    border-radius: 10px;
}

.top-bar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

.welcome-text {
    font-size: 40px;
    font-weight: 750;
    color: #ffffff;
    margin-top: 104px;
}

.stats-text {
    width: 94%;
    height: 40px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    margin-top: 16px;
}

.congrats-container {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000000;
    text-align: center;
    padding: 20px;
}

.top-bar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

.top-bar-item {
    width: 30%;
    height: 8px;
    background-color: #ccc;
    border-radius: 4px;
}

.top-bar-item.active {
    background-color: #000;
    border: 1px solid #fff;
}

.congrats-text {
    font-size: 1.5em;
    margin-bottom: 40px;
}

.congrats-text span {
    display: block;
    font-size: 2em;
    margin-bottom: 10px;
}

.coins-text {
    font-size: 1.5em;
    margin-bottom: 40px;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -1px) rotate(-1deg);
    }
    20% {
        transform: translate(-1px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 1px) rotate(-1deg);
    }
    60% {
        transform: translate(-1px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(1px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -1px) rotate(-1deg);
    }
}

.shake {
    animation: shake 0.5s; /* Duration of the shake */
    animation-iteration-count: 1; /* Shake only once */
}

.coin {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    -webkit-user-drag: none; /* Забороняє перетягування зображення */
    -webkit-tap-highlight-color: transparent; /* Вимикає сірий/синій фон на мобільних */
    box-shadow: none;
}

.coin:focus {
    outline: none;
    box-shadow: none;
}

.mobile-body {
    overflow: hidden;
    height: 100vh;
}

.mobile-wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: red;
}

.mobile-content {
    height: calc(100% + 1px);
    background: green;
}

/*.points-popup {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%); */
/*    color: #fff;*/
/*    font-size: 2em;*/
/*    background: rgba(0, 0, 0, 0.7);*/
/*    padding: 10px;*/
/*    border-radius: 5px;*/
/*    opacity: 1;*/
/*    animation: fade-out 1s ease-in-out;*/
/*    pointer-events: none; !* Ensure the text is not clickable *!*/
/*}*/

/*@keyframes fade-out {*/
/*    0% {*/
/*        opacity: 1;*/
/*        transform: translate(-50%, -50%) scale(1);*/
/*    }*/
/*    100% {*/
/*        opacity: 0;*/
/*        transform: translate(-50%, -100%) scale(0.5);*/
/*    }*/
/*}*/

.points-popup {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    animation: fadeUp 0.7s linear forwards;
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    20% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-150px);
    }
}

/* Start Menu */
.menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    overflow-x: auto;
}

.menu-container {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.bottom-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item {
    width: 15vw;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    flex: 1;
    padding: 8.5px 1.5vw;
    text-align: center;
    color: #fff;
    border-radius: 2vmin;
    text-decoration: none;
    font-size: 1.8vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-item:not(:last-of-type)::after {
    content: '';
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.1);
    right: 0;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 70%;
}

.menu .active {
    border: 1px solid;
    border-color:rgba(255, 255, 255, 0.8);
}

.menu-item-icon-wrapper {
    font-size: 24px;
    line-height: 20px;
}

.menu-item-icon {
    display: block;
}

.menu-item-label {
    margin-top: 8px;
    font-size: 14px; /* Font size for the label */
    line-height: 20px;
    color: white; /* White text color */
    font-family: Roobert, sans-serif; /* Font family */
    text-align: center;
}

/* End Menu */

.page {
    height: 100%;
    min-height: 100vh;
}


/* General Styles */
.tasks-page {
    padding: 0 0 20px 0;
    color: white;
}

h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    font-weight: bold;
}

/* Task List Styles */
.task-list {
    /* height: 490px; */
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
    background: rgba(60, 19, 97, 0.8); /* Semi-transparent background */
    padding: 15px;
}

.task-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1); /* Background for each task */
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.task-item:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Slightly lighter on hover */
}

/* Icon and Details */
.task-icon {
    font-size: 2em;
    margin-right: 10px;
}

.task-details {
    flex: 1;
    text-align: left;
}

.task-title {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 5px;
}

.task-coins {
    font-size: 1em;
    color: gold;
}

/* Action Button */
.task-action {
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.task-action:hover {
    transform: scale(1.1);
}

.tasks-header {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: left;
}

.task-item.inactive {
    background-color: rgba(255, 255, 255, 0.05); /* Different background color for inactive */
    opacity: 0.5; /* Make inactive tasks appear grayed out */
    cursor: not-allowed; /* Change cursor to indicate non-clickable */
}

.friends-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.wallet-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.button-white {
    height: 8vh;
    width: 50vw;
    font-size: 2.7vmin;
    border-radius: 2vmin;
    color: black;
    background: #FFFFFF;
    border-color: white;
}

.button-red {
    padding: 17px;
    text-align: center;
    color: white;
    border-radius: 12px;
    border-color: transparent;
    background: rgba(199, 17, 90, 1);
}

.coin-wrapper {
    height: 50vh;
    position: relative;
}

@media (max-height: 100vh) and (max-width: 100vw) {
  .small-screen {
    /* Add your small screen specific styles here */
    padding: 1.5vh 1.5vw;
    font-size: 2vh;
  }

  /* You can add more small screen specific styles as needed */
}
