.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  align-items: flex-end; /* Align to the bottom */
  justify-content: center;
  z-index: 1000; /* Ensure modal is on top */
  transition: opacity 0.3s ease; /* Smooth fade-in for the overlay */
}

.modal-content {
  height: 40%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1C1C1E;
  border-radius: 30px 30px 0 0; /* Rounded corners at the top */
  padding: 20px;
  width: 100%; /* Responsive width */
  max-width: 500px; /* Max width for desktop */
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  text-align: center; /* Center text */
  transform: translateY(100%); /* Start hidden below the screen */
  transition: transform 0.3s ease; /* Smooth slide up animation */
}

.modal-content.show {
  transform: translateY(0); /* Slide up into view */
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-title {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal-body {
  margin-bottom: 1rem;
}

.modal-action-button {
  background-color: #f0f0f0;
  border: none;
  color: black;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 1rem;
}
