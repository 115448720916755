.friends-page {
    height: 100%;
    min-height: calc(100vh - 130px);
    padding: 40px 0;
}

.tasks-page {
    flex-grow: 1; 
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tasks-page h1 {
    font-size: 40px;
    margin-bottom: 12px;
}

.tasks-page p {
    font-size: 16px;
}

.friends-page .button-red {
    width: 100%;
}

