.clan-name {
    width: 100%;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 20px;
}

.clan-name .clan-name__img {
    width: 52px;
    height: 52px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 16px;
}
.clan-name__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.clan-name__name .wallet-link {
    font-size: 30px;
}

.clan-name__name .clan-name__title {
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 4px;
}

.clan-name__name .clan-name__score {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.clan-name__name .clan-name__score img {
    width: 15px;
    height: 15px;
}

.wallet-link {
    cursor: pointer;
    display: inline-block;
}

.wallet-link span {
    color: white;
    font-size: 14px;
    font-family: monospace;
}