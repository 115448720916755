.booster-container  {
    gap: 8px;
}

.booster-container .task-item {
    width: 100%;
    font-size: 14px;
    backdrop-filter: blur(20px);
    justify-content: center;
    padding: 16px 10px;
}

.booster-container .task-item span {
    font-size: 30px;
    margin-right: 10px;
}

.booster-container .booster-title {
    font-weight: bold;
    font-size: 16px;
}

.boosts-list .upgrades-container .task-item {
    margin-bottom: 0;
    padding: 16px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.boosts-list .upgrades-container .task-item .task-item__element {
    display: flex;
    align-items: center;
    text-align: left;
}

.boosts-list .upgrades-container .task-item .task-item__element .task-item__element-icon {
    font-size: 30px;
    line-height: 30px;
    width: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 16px;
}

.boosts-list .upgrades-container .task-item .task-item__element .task-item__element-icon span {
    margin-bottom: 0;
    display: inline;
}

.boosts-list .upgrades-container .task-item span {
    margin-bottom: 0;
    display: inline;
}

.boosts-list .upgrades-container .task-item .button-red {
    margin-top: 0;
    width: 52px;
    height: 52px;
}

.boosts-list .upgrades-container:not(:last-of-type) {
    margin-bottom: 5px;
}