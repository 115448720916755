.yards-page {
    padding: 20px 0;
}

.yards-search {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.yards-search .yards-search__img {
    transform: translateY(3px);
    margin-right: 5px;
}

.yards-search .yards-search__img img {
    width: 16px;
    height: 16px;  
}

.yards-search input {
    all: unset;
    text-align: left;
    font-size: 16px;
    width: 100%;
    color: rgba(255, 255, 255, 0.3);

}

.yards-search__item:not(:last-of-type) {
    margin-bottom: 12px;
}

.yards-list {
    max-height: 70vh;
    position: relative;
    overflow-y: hidden;
   
}

.yards-container {
    max-height: 70vh;
    padding-bottom: 50px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: scroll;
    height: 100%;
}

.yards-container::-webkit-scrollbar {
    display: none; 
  }

.yards-list::after {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 95%);

    width: 100%;
    height: 40px;
    z-index: 2;
    left: 0;
    bottom: -1px;
    user-select: none;

}
.yards-search__item {
    padding: 12px;
    border: 1px solid rgba(162, 162, 161, 1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.yards-search__item-content {
    display: flex;
    align-items: center;
}

.yards-search__item-content .yards-search__item-img {
    width: 52px;
    height: 52px;
    border-radius: 8px;
    margin-right: 16px;
    overflow: hidden;
}

.yards-search__item-content .yards-search__item-name {
    font-size: 16px;
    font-weight: 650;
    text-align: left;
    line-height: 20.22px;
    color: white;
    margin-bottom: 4px;
}

.yards-search__item-content .yards-search__item-score {
    font-size: 14px;
    line-height: 17.7px;
    color: white;
    display: flex;
    align-items: center;
}

.yards-search__item-content .yards-search__item-score img {
    width: 15px;
    height: 15px;
    transform: translateY(3px);
    margin-right: 4px;
}

.yards-search__item-score-members {
    font-size: 14px;
    line-height: 17.7px;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 4px;
}

.yards-search__item-medal {
    font-size: 32px;
}

.yards-bottom-panel {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.yards-bottom-panel .button-red {
    width: 100%;
    margin-top: 20px;
}

.yards-page__create {
    padding-top: 40px;
}

.yards-page__create-title {
    font-size: 32px;
    font-weight: 750;
    line-height: 40.45px;
    text-align: center;
    color: white;
    margin-bottom: 40px;
}

.yards-page__pic-upload-icon {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 0 auto 16px auto;
    border: 1px solid rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 0.1);

}

.yards-page__pic-upload-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.7px;
    text-align: center;
    color: white;
}

.yards-page__pic-upload {
    margin-bottom: 44px;
}

.yards-page__create-name span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.22px;
    text-align: left;
    color: white;
    margin-bottom: 12px;
    display: block;
}

.yards-page__create-name input {
    all: unset;
    text-align: left;
    width: -webkit-fill-available;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    display: block;
}

.yards-page__create-name input::placeholder {
    color: rgba(255, 255, 255, 0.3);

}